import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import logo from '../logo.svg';
import './App.scss';
import Checkout from '../checkout/checkout';
import Footer from '../footer/Footer';
import Loading from './loading/Loading';
import Api from '../common/api';
import { pageTitle } from '../config';

function App() {
  const [loading, setLoading] = useState(false);
  const [serviceErrorText, setServiceErrorText] = useState('');
  const statefulAPIInstance = new Api(setLoading);
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className="App container">
        <div className="row justify-content-evenly">
          <Router>
            <Routes>
              <Route
                path="/success.html"
                element={<h5 className="post-payment-messages">Your invoice was paid.</h5>}
              />
              <Route
                path="/cancelled.html"
                element={<h5 className="post-payment-messages">Your payment was cancelled</h5>}
              />
              <Route
                path="/"
                element={
                  <Checkout
                    serviceErrorText={serviceErrorText}
                    apiInstance={statefulAPIInstance}
                    setServiceErrorText={setServiceErrorText}
                  />
                }
              />
            </Routes>
          </Router>
        </div>
        <div className="row">
          <Footer apiInstance={statefulAPIInstance} />
        </div>
      </div>
    </>
  );
}

export default App;
