import axios from 'axios';
import { baseUrl } from '../config';
import * as errorMessages from '../constants/errorMessages';

export default class Api {
  constructor(updateLoadingFlag) {
    this.updateLoadingFlag = updateLoadingFlag;
    axios.defaults.validateStatus = () => {
      return true;
    };
  }

  get = async (url, params, headers) => {
    return this.#fetch({ method: 'GET', url, params, headers });
  };

  post = async (url, data, headers) => {
    return this.#fetch({ method: 'POST', url, data, headers });
  };

  async #fetch({ method, url, data, params, headers }) {
    this.updateLoadingFlag?.(true);
    let axiosPromise;
    try {
      axiosPromise = await axios({
        method,
        baseURL: baseUrl,
        url,
        data,
        headers,
        params
      });
      return axiosPromise?.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(axiosPromise, e);
      throw new Error(`${e.code} -  ${errorMessages.serviceError.genericError}`);
    } finally {
      this.updateLoadingFlag?.(false);
    }
  }
}
