const useCurrencyFormat = (locale, currency) => {
  const defaults = {
    locale: 'en-US',
    currency: 'USD'
  };
  const formatter = (num) => {
    try {
      if (num === '' || Number.isNaN(num) || num === undefined || num === null) {
        return undefined;
      }
      let parsedNum;
      if (!Number.isInteger(num)) {
        parsedNum = parseFloat(num);
      } else {
        parsedNum = num;
      }
      const res = parsedNum.toLocaleString(locale || defaults.locale, {
        style: 'currency',
        currency: currency || defaults.currency
      });
      return res;
    } catch (error) {
      throw new Error('Error while formatting number to currency');
    }
  };
  return formatter;
};

const hooks = {
  useCurrencyFormat
};

export default hooks;
