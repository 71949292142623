module.exports = {
  serviceError: {
    genericError: 'Error while calling Pay Services. Please reach out to SurePoint Pay Support.',
    checkoutSession:
      'Check out failed. Please try again. If the problem persists, contact the payment requester for assistance.',
    R1: `Go back to the firm’s website and try again.`,
    E1: 'Payment link has expired. Go back and retrieve a new link.'
  },
  loadingErrors: {
    remoteIdMissing:
      'Missing account ID or the UUID. Contact the payment requester for assistance.',
    remoteIdWrong: 'Invalid account ID or UUID. Contact the payment requester for assistance.',
    accountDetailsWrong: 'Invalid account details. Contact the payment requester for assistance.'
  }
};
