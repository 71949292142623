import './loading.scss';

export default function Loading({ loading }) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading && (
        <div className="loader-container">
          <div className="lds-grid">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </>
  );
}
