module.exports = {
  api: {
    accountDetails: 'account-details',
    createCheckout: 'create-checkout-session',
    getMerchantAccount: 'merchant/accounts',
    validateURL: 'validate-url',
    getFooter: 'messages'
  },
  display: {
    defaultLogoImage: '/onlinePayment.svg',
    defaultpayConfig: {
      allowConvenienceFee: false,
      cardConvenienceFee: 0.03,
      maxAmount: 1000000, // cents
      minAmount: 50000 // cents
    }
  },
  queryParams: {
    metaDataMatcher: /meta\[(.*?)\]/
  },
  form: {
    disallowedNumberFieldCharRegex: /^[e+-]/,
    maxAllowedPayValueinUI: 9999999999.99,
    allowOnlyTwoDigitDecimals: /^(\d{0,10}|\d{0,10}\.\d{0,2})$/,
    allowOnlyInt: /^(\d{0,8}|\d{0,8})$/,
    disallowedInvFieldCharRegex: /^[Ee+-.]/,
    maxAllowedInvNoinUI: 99999999
  },
  methodFee: {
    card: 'card',
    us_bank_account: 'us_bank_account'
  },
  payMinAmount: 0.5
};
