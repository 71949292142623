import { useEffect, useState } from 'react';
import currency from 'currency.js';
import { PaymentForm, PaymentOverview } from '../payment';
import * as errorMessages from '../constants/errorMessages';
import * as appConstants from '../constants/appConstants';
import { getMatchingParams } from '../services/app.service';
import getAccountDetails from '../helper-functions/getAccountDetails';

function Checkout({ serviceErrorText, apiInstance, setServiceErrorText }) {
  const [remoteId, setRemoteId] = useState('');
  const [metaParams, setMetaParams] = useState({});
  const [firmUUID, setFirmUUID] = useState('');
  const [email, setEmail] = useState('');
  const [accountName, setAccountName] = useState('');
  const [totalAmount, setTotalAmount] = useState();
  const [logoURL, setLogoURL] = useState('');
  const [payConfig, setPayConfig] = useState({});
  const [merchantId, setMerchantId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const { defaultpayConfig } = appConstants.display;
  const [encryptedValue, setEncryptedValue] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(appConstants.methodFee.card);

  const handleAccountData = (res) => {
    setAccountName(res.data.accountName || '');
    setLogoURL(res.data.logoURL || appConstants.display.defaultLogoImage);
    setPayConfig({
      minAmount: currency((res.data.config?.minAmount || defaultpayConfig.minAmount) / 100).value,
      maxAmount: currency((res.data.config?.maxAmount || defaultpayConfig.maxAmount) / 100).value,
      cardConvenienceFee: !Number.isNaN(res.data.config?.cardConvenienceFee)
        ? res.data.config?.cardConvenienceFee
        : defaultpayConfig.cardConvenienceFee,
      allowConvenienceFee:
        res.data.config?.allowConvenienceFee || defaultpayConfig.allowConvenienceFee,
      checkoutMessage: res.data.config?.checkoutMessage,
      echeckConvenienceFee: !Number.isNaN(res.data.config?.echeckConvenienceFee)
        ? res.data.config?.echeckConvenienceFee
        : defaultpayConfig.cardConvenienceFee
    });
    setServiceErrorText('');
  };

  const handleAccountDetailsError = (e) => {
    // eslint-disable-next-line no-console
    console.error('get api error', e);
    setAccountName('');
    setLogoURL('');
    setPayConfig({});
    if (e.message === 'Account Details invalid') {
      setServiceErrorText(errorMessages.loadingErrors.accountDetailsWrong);
      return;
    }
    setServiceErrorText(errorMessages.loadingErrors.remoteIdWrong);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramRemoteId = params.get('accountId');
    const paramFirmUUID = params.get('uuid');
    const paramEmail = params.get('email');
    const paramMerchantId = params.get('mId');
    const paramEncrypted = params.get('e');

    if (paramEncrypted) {
      const validateURL = async () => {
        const res = await apiInstance?.get(
          appConstants.api.validateURL,
          {
            e: paramEncrypted,
            refererURL: document.referrer
          },
          null
        );
        if (res?.code) {
          setServiceErrorText(errorMessages.serviceError[res.code]);
        } else {
          setEncryptedValue(paramEncrypted);
          if (!paramRemoteId && !paramFirmUUID && !paramMerchantId) {
            setServiceErrorText(errorMessages.loadingErrors.remoteIdMissing);
          } else {
            setRemoteId(paramRemoteId);
            setFirmUUID(paramFirmUUID);
            setMerchantId(paramMerchantId);
          }
          if (paramEmail) setEmail(paramEmail);
          const mParams = getMatchingParams(params, appConstants.queryParams.metaDataMatcher);
          setMetaParams(mParams);
        }
      };
      validateURL();
    } else {
      if (!paramRemoteId && !paramFirmUUID && !paramMerchantId) {
        setServiceErrorText(errorMessages.loadingErrors.remoteIdMissing);
      } else {
        setRemoteId(paramRemoteId);
        setFirmUUID(paramFirmUUID);
        setMerchantId(paramMerchantId);
      }
      if (paramEmail) setEmail(paramEmail);
      const mParams = getMatchingParams(params, appConstants.queryParams.metaDataMatcher);
      setMetaParams(mParams);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (remoteId || firmUUID) {
        try {
          const group = !metaParams.group ? {} : { group: metaParams.group };
          const res = await getAccountDetails(
            apiInstance,
            appConstants.api.accountDetails,
            remoteId,
            firmUUID,
            group,
            encryptedValue,
            merchantId,
            document.referrer
          );

          // TODO: uncomments the check
          if (res?.data) {
            if (res?.data?.config?.allowLinkExpiration && !encryptedValue && !merchantId) {
              setServiceErrorText(errorMessages.serviceError.E1);
            } else {
              handleAccountData(res);
            }
          } else if (res?.code) {
            setServiceErrorText(errorMessages.serviceError[res.code]);
          } else {
            throw new Error('Account Details invalid');
          }
        } catch (e) {
          handleAccountDetailsError(e);
        }
      }
    })();
  }, [remoteId]);

  useEffect(() => {
    (async () => {
      if (merchantId) {
        try {
          const res = await apiInstance?.get(
            appConstants.api.getMerchantAccount,
            {
              id: merchantId,
              e: encryptedValue,
              refererURL: document.referrer
            },
            null
          );

          if (res.success) {
            setAccounts(res.data.accounts);
            setLogoURL(res.data.logoURL);
          } else if (res?.code) {
            setServiceErrorText(errorMessages.serviceError[res.code]);
          } else {
            setServiceErrorText('Invalid merchant details');
          }
        } catch (e) {
          handleAccountDetailsError(e);
        }
      }
    })();
  }, [merchantId]);

  const submitPayment = async (data) => {
    if (data.paymentClientName) metaParams.client_name = data.paymentClientName;
    try {
      const res = await apiInstance.post(
        appConstants.api.createCheckout,
        {
          id: firmUUID,
          remoteId,
          paymentAmount: currency(totalAmount).value,
          ...data,
          metadata: metaParams,
          e: encryptedValue,
          mId: merchantId,
          refererURL: document.referrer,
          paymentMethod: data.paymentMethod
        },
        undefined
      );
      if (res?.data?.redirectTo) {
        window.location.href = res.data.redirectTo;
      } else if (res?.code) {
        setServiceErrorText(errorMessages.serviceError[res.code]);
      } else {
        throw new Error(errorMessages.serviceError.checkoutSession);
      }
    } catch (e) {
      setServiceErrorText(e?.message || e);
    }
  };

  return (
    <>
      <PaymentOverview
        totalAmount={currency(totalAmount).value}
        accountName={accountName}
        logoURL={logoURL}
        isConvenienceFeeApplied={payConfig?.allowConvenienceFee || false}
        invoiceAmount={
          Number.isFinite(+metaParams.invoice_amount) ? +metaParams.invoice_amount : ''
        }
        invoiceNumber={metaParams.invoice_number ? metaParams.invoice_number : ''}
        checkoutMessage={payConfig.checkoutMessage}
        paymentMethod={paymentMethod}
        cardConvenienceFee={payConfig.cardConvenienceFee}
        echeckConvenienceFee={payConfig.echeckConvenienceFee}
      />
      <PaymentForm
        clientName={metaParams.client_name}
        setTotalAmount={setTotalAmount}
        email={email}
        serviceErrorText={serviceErrorText}
        submitPayment={submitPayment}
        payConfig={payConfig}
        accounts={accounts}
        setRemoteId={setRemoteId}
        remoteId={remoteId}
        merchantId={merchantId}
        setPaymentMethod={setPaymentMethod}
        paymentMethod={paymentMethod}
      />
    </>
  );
}

export default Checkout;
