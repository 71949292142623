import { useEffect, useState } from 'react';
import './Footer.scss';
import appConstants from '../constants/appConstants';

function Footer({ apiInstance }) {
  const [footerMessage, setFooterMessage] = useState('');

  useEffect(() => {
    const getFooterMessage = async () => {
      const res = await apiInstance?.get(appConstants.api.getFooter);

      if (res.success) {
        setFooterMessage(res.data[0].message);
      }
    };
    getFooterMessage();
  }, []);

  return (
    <footer className="App-Footer Footer">
      <div className="small">{footerMessage}</div>
      <img alt="" width="150px" src="/secure-checkout.png" />
      <div className="d-flex">
        <div className="Footer-PoweredBy">
          <a className="Link" href="https://surepoint.com" target="_blank" rel="noreferrer">
            <span className="Text">Copyrights</span>
          </a>
        </div>
        <div className="Footer-Links">
          <a
            className="Link"
            href="https://surepoint.com/terms-of-service/"
            target="_blank"
            rel="noreferrer">
            <span className="Text">Terms</span>
          </a>
          <a
            className="Link Link--primary"
            href="https://surepoint.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer">
            <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
              Privacy
            </span>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
