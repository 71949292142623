const getAccountDetails = async (
  apiInstance,
  url,
  remoteId,
  id,
  group,
  encryptedValue,
  merchantId,
  refererURL
) => {
  if (remoteId || id) {
    const res = await apiInstance?.get(
      url,
      {
        remoteId,
        id,
        ...group,
        e: encryptedValue,
        mId: merchantId,
        refererURL
      },
      null
    );

    return res;
  }

  return false;
};

export default getAccountDetails;
