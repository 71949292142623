import currency from 'currency.js';

export const getConvenienceFee = (amount, commission) => {
  return +parseFloat(currency(amount).multiply(commission || 0)).toFixed(2);
};

export const otherServiceMethod = () => {
  // do something
};

export const getMatchingParams = (params, matcher) => {
  const mParams = {};
  params.forEach((v, k) => {
    const matchedMetaKey = matcher.exec(k);
    if (matchedMetaKey) {
      mParams[matchedMetaKey[1]] = v;
    }
  });

  return mParams;
};
